<template>
    <div class="travel-fields">aaa
        <a-row :gutter="20">
            <a-col :span="12">
                <a-form-item class="travel-input">
                    <label
                        :class="
                            form.getFieldValue(`company`) ? 'filled' : 'filled'
                        "
                        >Empresa</label
                    >
                    <a-select
                        class="travel-input"
                        show-search
                        placeholder="Selecione"
                        v-decorator="['company']"
                    >
                        <a-select-option
                            v-for="(item, index) of companies"
                            :key="index.label"
                            :value="item.label"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input">
                    <label
                        :class="
                            form.getFieldValue(`user`) ? 'filled' : 'filled'
                        "
                        >Consultor responsável</label
                    >
                    <a-select
                        class="travel-input"
                        show-search
                        placeholder="Selecione"
                        v-decorator="['user']"
                    >
                        <a-select-option
                            v-for="(item, index) of users"
                            :key="index.label"
                            :value="item.id"
                        >
                            {{ item.id }} - {{ item.first_name }}
                            {{ item.last_name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            this.form.getFieldValue('id_infotera')
                                ? 'filled'
                                : 'filled'
                        "
                        >ID Infotera</label
                    >
                    <a-input
                        type="number"
                        class="travel-input readonly"
                        placeholder="ID Infotera"
                        v-decorator="['id_infotera']"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>



            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`release_date`)
                                ? 'filled'
                                : 'filled'
                        "
                        >Data do lançamento</label
                    >
                    <a-date-picker
                        placeholder="Selecione"
                        format="DD/MM/YYYY"
                        valueFormat="YYYY-MM-DD"
                        :showToday="false"
                        :allowClear="true"
                        v-mask="'##/##/####'"
                        v-decorator="[
                            'release_date',
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-date-picker>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`created`) ? 'filled' : 'filled'
                        "
                        >Data de criação</label
                    >
                    <a-date-picker
                        placeholder="Selecione"
                        format="DD/MM/YYYY HH:mm"
                        valueFormat="YYYY-MM-DD HH:mm"
                        :showToday="false"
                        :allowClear="true"
                        :disabled="true"
                        v-mask="'##/##/####'"
                        v-decorator="[
                            'created',
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-date-picker>
                </a-form-item>
            </a-col>
        </a-row>

        <a-row v-if="contract.meta.contract_done && $root.isAdmin()">
            <a-col :span="14">
                <a-checkbox
                    class="mt-10"
                    v-model="allowUserToEdit"
                    style="
                        font-size: 13px;
                        font-weight: 500;
                        position: relative;
                    "
                    @change="onChangeAllowUser"
                    :disabled="alowEditLoading"
                >
                    Permitir edição do usuário durante
                    <b>{{ allowMinutes }} minutos</b>.
                </a-checkbox>

                <a-form-item style="display: none">
                    <a-input
                        v-decorator="[
                            'allow_user_edit',
                            {
                                initialValue: allowUserToEdit,
                            },
                        ]"
                        :value="allowUserToEdit"
                    />
                </a-form-item>
            </a-col>
            <a-col :span="4">
                <div>
                    <a-input-number
                        v-model="allowMinutes"
                        :disabled="alowEditLoading"
                        :min="20"
                        :max="60"
                        :step="5"
                        @change="onMinutesChange"
                    />
                </div>
            </a-col>
            <a-col
                v-if="
                    tempContract['allow_user_edit_expiration_date_time'] !=
                    undefined
                "
                :span="6"
            >
                <div style="position: relative; top: -5px">
                    <div style="font-size: 12px; font-weight: 500">
                        Expirará em:
                    </div>
                    {{ tempContract["allow_user_edit_expiration_date_time"] }}
                </div>
            </a-col>
        </a-row>
        <a-row v-else>
            <a-col :span="24">
                <a-alert class="f12" type="info" banner>
                    <template slot="message">
                        A opção para permitir edição do usuário será
                        disponibilizada após a <b>GERAÇÃO DO CONTRATO</b>.
                    </template>
                </a-alert>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import { addMinutes } from "date-fns";

export default {
    name: "ContractSettings",
    mixins: [formatThings],
    props: {
        form: Object,
        contract: Object,
        tempContract: Object,
        allowUserToEdit: Boolean,
    },
    data() {
        return {
            allowMinutes: 20,
            editExpiration: undefined,
            alowEditLoading: false,
            companies: [
                {
                    label: "Viajar Resorts",
                    value: "Viajar Resorts",
                },
                {
                    label: "Voe Simples",
                    value: "Voe Simples",
                },
                {
                    label: "Credimilhas",
                    value: "Credimilhas",
                },
            ],
            users: [],
        };
    },
    beforeMount() {
        this.allowMinutes =
            this.tempContract["allow_user_edit_expiration_minutes"] != undefined
                ? parseInt(
                      this.tempContract["allow_user_edit_expiration_minutes"]
                  )
                : 20;

        if (
            this.tempContract.status == "concluded" ||
            this.tempContract.status == "canceled"
        ) {
            this.onMinutesChange(this.allowMinutes);
        }

        this.$http
            .get("/user/list?page=1&per_page=100")
            .then(({ data }) => {
                this.users = data.data;
                this.form.setFieldsValue({
                    [`user`]: this.tempContract["user"],
                });
            })
            .catch(({ response }) => {
                this.$message.error(response.data.message);
            });

        setTimeout(() => {
            this.form.setFieldsValue({
                [`release_date`]:
                    this.tempContract["release_date"] == "0000-00-00"
                        ? undefined
                        : this.tempContract["release_date"],
                [`created`]: this.tempContract["created"],
                [`company`]: this.tempContract["company"],
                id_infotera:
                    this.tempContract.id_infotera != 0
                        ? this.tempContract.id_infotera
                        : undefined,
            });
        }, 10);
    },
    methods: {
        onMinutesChange(val) {
            let expiration = addMinutes(new Date(), val);

            this.editExpiration = expiration.getTime();
            this.tempContract["allow_user_edit_expiration_time"] =
                expiration.getTime();
            this.tempContract["allow_user_edit_expiration_date_time"] =
                this.formatDateTimeObj(expiration);
            this.tempContract["allow_user_edit_expiration_minutes"] =
                this.allowMinutes;
        },
        onChangeAllowUser(val) {
            this.alowEditLoading = true;
            this.tempContract["allow_user_edit"] = val.target.checked
                ? "1"
                : "0";

            if (this.tempContract["allow_user_edit"] == "1") {
                this.tempContract["action"] = "update-user-permission";

                this.tempContract[
                    "description"
                ] = `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> liberou a edição para o contrato <font class="id">ID ${this.tempContract.id}</font>. Clique nesta notificação para acessar.`;

                this.tempContract["modified_by"] = {
                    name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                    id: this.$store.state.userData.id,
                };
            }

            this.$http
                .post("/contract/update", this.tempContract)
                .then(({ data }) => {
                    data;
                    this.alowEditLoading = false;
                    this.$message.success(
                        "Permissões do usuário atualizadas com sucesso!"
                    );
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.alowEditLoading = false;
                });
        },
    },
};
</script>
