var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel-fields"},[_vm._v("aaa\n    "),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(`company`) ? 'filled' : 'filled'},[_vm._v("Empresa")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['company']),expression:"['company']"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.companies),function(item,index){return _c('a-select-option',{key:index.label,attrs:{"value":item.label}},[_vm._v("\n                        "+_vm._s(item.label)+"\n                    ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(`user`) ? 'filled' : 'filled'},[_vm._v("Consultor responsável")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user']),expression:"['user']"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.users),function(item,index){return _c('a-select-option',{key:index.label,attrs:{"value":item.id}},[_vm._v("\n                        "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+"\n                        "+_vm._s(item.last_name)+"\n                    ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:this.form.getFieldValue('id_infotera')
                            ? 'filled'
                            : 'filled'},[_vm._v("ID Infotera")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id_infotera']),expression:"['id_infotera']"}],staticClass:"travel-input readonly",attrs:{"type":"number","placeholder":"ID Infotera"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`release_date`)
                            ? 'filled'
                            : 'filled'},[_vm._v("Data do lançamento")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                        'release_date',
                        {
                            rules: [
                                {
                                    required: false,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]),expression:"[\n                        'release_date',\n                        {\n                            rules: [\n                                {\n                                    required: false,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","valueFormat":"YYYY-MM-DD","showToday":false,"allowClear":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`created`) ? 'filled' : 'filled'},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                        'created',
                        {
                            rules: [
                                {
                                    required: false,
                                    message: 'Obrigatório',
                                },
                            ],
                        },
                    ]),expression:"[\n                        'created',\n                        {\n                            rules: [\n                                {\n                                    required: false,\n                                    message: 'Obrigatório',\n                                },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","valueFormat":"YYYY-MM-DD HH:mm","showToday":false,"allowClear":true,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1),_vm._v(" "),(_vm.contract.meta.contract_done && _vm.$root.isAdmin())?_c('a-row',[_c('a-col',{attrs:{"span":14}},[_c('a-checkbox',{staticClass:"mt-10",staticStyle:{"font-size":"13px","font-weight":"500","position":"relative"},attrs:{"disabled":_vm.alowEditLoading},on:{"change":_vm.onChangeAllowUser},model:{value:(_vm.allowUserToEdit),callback:function ($$v) {_vm.allowUserToEdit=$$v},expression:"allowUserToEdit"}},[_vm._v("\n                Permitir edição do usuário durante\n                "),_c('b',[_vm._v(_vm._s(_vm.allowMinutes)+" minutos")]),_vm._v(".\n            ")]),_vm._v(" "),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'allow_user_edit',
                        {
                            initialValue: _vm.allowUserToEdit,
                        },
                    ]),expression:"[\n                        'allow_user_edit',\n                        {\n                            initialValue: allowUserToEdit,\n                        },\n                    ]"}],attrs:{"value":_vm.allowUserToEdit}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('div',[_c('a-input-number',{attrs:{"disabled":_vm.alowEditLoading,"min":20,"max":60,"step":5},on:{"change":_vm.onMinutesChange},model:{value:(_vm.allowMinutes),callback:function ($$v) {_vm.allowMinutes=$$v},expression:"allowMinutes"}})],1)]),_vm._v(" "),(
                _vm.tempContract['allow_user_edit_expiration_date_time'] !=
                undefined
            )?_c('a-col',{attrs:{"span":6}},[_c('div',{staticStyle:{"position":"relative","top":"-5px"}},[_c('div',{staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v("\n                    Expirará em:\n                ")]),_vm._v("\n                "+_vm._s(_vm.tempContract["allow_user_edit_expiration_date_time"])+"\n            ")])]):_vm._e()],1):_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-alert',{staticClass:"f12",attrs:{"type":"info","banner":""}},[_c('template',{slot:"message"},[_vm._v("\n                    A opção para permitir edição do usuário será\n                    disponibilizada após a "),_c('b',[_vm._v("GERAÇÃO DO CONTRATO")]),_vm._v(".\n                ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }